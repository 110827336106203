@import '../../../variables';

.buttonWrapper {
    $parent-border-width: 2px;

    position: relative;
    display: inline-block;
    border: $parent-border-width solid #ddd;

    .button {
        background: transparent;
        border: none;
        padding: 12px 40px 10px;
        color: #666;
        font-weight: 500;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;
    }

    &:hover {
        .underlineFill {
            transform: scale(1,1);
        }
    }

    .underlineFill {
        position: absolute;
        bottom: -#{$parent-border-width};
        left: -#{$parent-border-width};
        height: ($parent-border-width * 2);
        width: calc(100% + (#{$parent-border-width} * 2));
        transform: scale(0,1);
        transform-origin: 0px;
        border-radius: 8px;
        transition: transform 0.6s ease;
    }
}
