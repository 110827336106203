@import '../../../variables';
@import '../../../helpers';

.addButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $accent-color;
    padding: 0;
    border: none;
    border-radius: 50%;
    z-index: 2;
    cursor: pointer;

    @include icon-shadow-elevation(2);

    &:hover {
        @include icon-shadow-elevation(3);
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.focusRing {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    &:focus-within {
        border: 5px solid $tab-colour;
    }
}

.icon {
    fill: #FFF;
}
