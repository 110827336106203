@import '../../../variables';

.tableRow {
    border-bottom: solid 1px #ededed;

    td {
        transition: background 0.5s ease;
    }

    &:hover td {
        transition: background 0.3s ease;
        background-color: #efefef;
    }
}
