@import '../../../../variables';
@import '../../../../helpers';

.cardActionMenu {
    flex: 1 1 auto;
    justify-content: flex-end;
    padding: 18px 10px;

    > .actionWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

 .actionFillHover:hover .cardActionMenu button:only-of-type div {
     & div {
         width: 100%;
     }
 }
