@import '../../../variables';

.container {
    position: relative;
    width: 280px;
    padding: 8px 8px 8px 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #757575;
    font-size: 1.1em;
    user-select: none;
}

.icon {
    display: block;
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    margin-right: 6px;
    fill: currentColor;
}
