@import '../../variables';

.label {
    composes: inputLabel from './InputLabel.module.scss';
}

.value {
    color: $input-filled-color;
    height: 36px;
    display: flex;
    align-items: center;
}
