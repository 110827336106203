@import '../../../variables';
@import '~sassdash/index';
@import '~sass-svg/index';

.selectInput {
    $textColor: #666;
    $placeholderColor: #999;

    box-sizing: border-box;
    display: block;
    background: #fff;
    border: $input-border-active;
    width: 100%;
    max-width: 400px;
    padding: 8px 28px 8px 8px;
    color: $textColor;
    font-size: 1.1em;
    border-radius: 4px;

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.placeholder {
        color: $input-placeholder-color;
        font-style: italic;
        font-weight: 300;
    }

    transition: box-shadow ease $ui-animation-duration;

    // Remove the awkward looking focus outline
    outline: none;

    // Firefox uses a psuedo element for the normal outline
    &::-moz-focus-inner, &::-moz-focusring {
        border: none;
        outline: none;
    }

    // It also has an outline around the option element position which is normally hidden.
    // See this answer for why this works https://stackoverflow.com/a/11603104/3270837
    // !important is needed to workaround bug where this would not override the color
    @supports (-moz-appearance: meterbar) {
        text-shadow: 0 0 0 $textColor;

        &.placeholder {
            text-shadow: 0 0 0 $placeholderColor;
        }
    }

    // Add a simpler focus state
    &:focus {
        border: $input-border-hover;
    }

    &:hover {
        border: $input-border-hover;
    }

    // Hide the default select arrow
    appearance: none;
    &::-ms-expand {
        display: none;
    }

    // Add a nice SVG one as a background image
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    background-size: 24px 24px;

    @include svg((viewBox: 0 0 24 24, "xml:space": preserve)) {
        @include svg('path', (
            fill: #9c9c9c,
            opacity: 1,
            d: "M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41
                0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"
        ));
    }
}

.altBackground {
    background-color: #eee;
}
