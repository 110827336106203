@import '../../../variables';

.container {
    display: flex;
    align-items: center;
}

.symbol {
    $pi: 3.14159265359;
    $radius: 40;
    $circumference: 2 * $pi * 40;

    > svg {
        display: block;
        width: 60px;
        height: 60px;
        fill: none;

        > .static, > .animated {
            stroke-width: 8;
            stroke: #666;
            stroke-linecap: round;
            transform-origin: center;
        }

        > .static {
            stroke-dasharray: $circumference * 0.05, $circumference * 0.9;
            animation: loading-spinner-static ease 2s infinite,
                       loading-spinner-rotate linear 1.5s infinite;

            @keyframes loading-spinner-static {
                from { stroke: #878787; }
                50% { stroke: #666; }
                to { stroke: #878787; }
            }
        }

        > .animated {
            stroke-dasharray: $circumference, $circumference;

            animation: loading-spinner-animated ease 2s infinite,
                       loading-spinner-rotate linear 1.5s infinite;
        }

        @keyframes loading-spinner-animated {
            from {
                stroke-dashoffset: $circumference;
                stroke: #878787;
            }
            50% {
                stroke: #666;
            }
            to {
                stroke-dashoffset: -$circumference;
                stroke: #878787;
            }
        }

        @keyframes loading-spinner-rotate {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
        }
    }

    @at-root _:-ms-fullscreen, :root & > svg {
        /* IE11 Fallback */
        animation: loading-spinner-rotate linear 0.8s infinite;

        > .static {
            opacity: 0.25;
            stroke-dasharray: none;
        }

        > .animated {
            stroke-dasharray: $circumference * 0.8;
        }
    }
}

.label {
    margin-left: $gutter-width;
    color: #666;
}

