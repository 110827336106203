@import '../../../variables';

$tab-card-ui: '../tabs/Tabs.module.scss';

.headerWrapper {
    box-sizing: border-box;

    &.hasGutter {
        margin: 0;

        @media (min-width: 960px) {
            margin: 0 ($gutter-width / 2);
        }

        @media (min-width: 1140px) {
            margin: 0 $gutter-width;
        }
    }

    &.hasGutter.full {
        margin: 0;
    }

    &.isDetached {
        margin-bottom: $gutter-width;
    }
}

.header {
    box-sizing: border-box;
    position: relative;
    background: #fff;
    color: #b1b1b1;
    fill: #b1b1b1;
    border-bottom: 2px solid #EEEEEE;
    border-radius: 8px 8px 0px 0px;

    &.boxed {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }

    &.full.hasGutter {
        padding: 0 $gutter-width;
    }

    &.isNested {
        @media (max-width: 959px) {
            background: #fbfbfb;
            box-shadow: none;
        }
    }

    &.dark {
        background: $grey-light;
    }
}

.headerInner {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
    padding: 0 ($gutter-width / 2);

    @media (min-width: 960px) {
        padding: 0 $gutter-width;
    }

    &.full {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }

    &.hasTopShadow {
        box-shadow: $container-shadow;
    }

    &.isNested {
        padding: ($gutter-width / 2)0;

        @media (min-width: 960px) {
            padding: 0 $gutter-width;
        }
    }
}

.primaryHeader {
    composes: header;
    height: 130px;

    &.isNested {
        height: auto;

        @media (min-width: 960px) {
            height: 130px;
        }
    }
}

.secondaryHeader {
    composes: header;
    height: 65px;
}

.headerContent {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    &.isNested {
        display: none;

        @media (min-width: 960px) {
            display: flex;
        }
    }
}

.dataTableFilter,
.titleContainer {
    flex: 1 1 auto;
    width: 100%;
}

.noTabs .title {
    margin: 0;
}

.title {
    display: flex;
    align-items: center;
    color: #555;
    font-weight: 600;
    font-size: 18px;

    .dark & {
        color: #fff;
    }
}

.tabContainer {
    position: absolute;
    bottom: 0px;
    left: ($gutter-width / 2);

    @media (min-width: 960px) {
        left: $gutter-width;
    }
}

.isNested .tabContainer {
    position: relative;

    @media (min-width: 960px) {
        position: absolute;
    }
}

.backButton {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 0px;
    background: none;
    border: none;

    @media (min-width: 960px) {
        left: 18px;
    }
}

.backButtonArrow {
    display: block;
    width: 24px;
    height: 24px;
    fill: #878787;
}

.stepListContainer {
    position: absolute;
    bottom: 12px;
    left: 30px;
}

.inlineButton {
    position: absolute;
    right: ($gutter-width / 2);
    top: 100%;
    transform: translateY(-50%);
    z-index: 2;
}

.headerContent {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;

    &.isNested {
        display: none;

        @media (min-width: 960px) {
            display: flex;
        }
    }
}
