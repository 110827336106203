@import '../../../variables';
@import '../../../helpers';

.drawer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    z-index: 3;
    overflow: hidden;
    transition: 1s ease;
    box-shadow: -10px 10px 10px 0px rgba(0, 0, 0, 0.16);
    background: white;

    &.hidden {
        transform: translateX(105%);
    }

    &.visible {
        transform: translateX(0);
    }
}

.drawerFooter {
    display: flex;
    padding: $gutter-width;
}

.drawerHeader {
    background: #fff;
    height: 130px;
    display: flex;
    align-items: center;
    padding: $gutter-width;
    border-bottom: 2px solid #EEEEEE;
    z-index: 2;
}

.drawerContent {
    padding: $gutter-width;
}

.title {
    font-size: 2em;
    font-weight: 500;
    color: #666;
}

.backButton {
    position: absolute;
    top: 8px;
    left: 3px;
    padding: 0;
    background: none;
    border: none;
}

.svgIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;

    @media (min-width: 600px) {
        width: 26px;
        height: 26px;
    }
    fill: #878787;
}

.disableScrolling {
    height: 100%;
    overflow: hidden;
}
