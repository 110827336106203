@import '../../../variables';

.button {
    margin: 0px;
    padding: 10px;
    min-height: 38px;
    min-width: 150px;
    font-weight: 500;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    font-family: "roboto-condensed";
    cursor: pointer;

    transition: background-color ease $ui-animation-duration,
                box-shadow ease $ui-animation-duration,
                color ease $ui-animation-duration;

    > span {
        position: relative;
        overflow: hidden;
        white-space: nowrap;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}
