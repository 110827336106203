.animationContainer {
    transition: opacity 0.6s, transform 0.3s ease;
    opacity: 0;
    transform: translateY(5vh);
    backface-visibility: hidden;
  
    &.entered {
      transform: translateY(0vh);
      opacity: 1;
    }
}

.animationContainerNotification {
    transition: opacity 0.6s, transform 0.3s ease;
    opacity: 0;
    transform: translateY(5vh);
    backface-visibility: hidden;
    display: flex;
    justify-content: space-between;
  
    &.entered {
      transform: translateY(0vh);
      opacity: 1;
    }
}

.animationContainerShowMore {
  transition: opacity 0.6s, transform 0.3s ease;
  opacity: 0;
  backface-visibility: hidden;

  &.entered {
    opacity: 1;
  }
}

.animationWobble {
  transition: 0.25s;
  transform: translate(0%, 0px);

  &.entering {
    transition-delay: 1.3s;
    transform: translate(0%, -10px);
  }

  &.entered {
    transform: translate(0%, 0px);
  }
}

.animationContainerUpload {
  transition: opacity 0.6s, transform 0.3s ease;
  opacity: 0;
  transform: translateY(5vh);
  backface-visibility: hidden;
  display: flex;
  height: 100%;
  width: 100%;

  &.entered {
    transform: translateY(0vh);
    opacity: 1;
  }
}

.animationContainerPayRun {
  transition: opacity 0.6s, transform 0.3s ease;
  opacity: 0;
  transform: translateY(5vh);
  backface-visibility: hidden;
  width: 100%;

  &.entered {
    transform: translateY(0vh);
    opacity: 1;
  }
}
