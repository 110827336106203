@import '../../../variables';
@import '../../../helpers';

.wrapper {
    position: relative;
    width: 200px;
}

.input {
    composes: input from './TextInput.module.scss';
    width: 200px;
    padding-right: 16px;

    &.inputBlurred:invalid {
        border: $input-border-error;
        box-shadow: 0px 0px 4px 0px #DC3545;
    }

    // Hide the spinner controls in Firefox
    appearance: textfield;

    // And Chrome/WebKit
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        display: none;
    }
}
