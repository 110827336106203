@import '../../../variables';
@import '../../../helpers';

.actionMenuButton {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    margin: 0px 0px 30px 0px;
    padding: 0px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.9em;
    color: #333;

    &::after {
        display: block;
        content: '';
        float: right;
        margin-left: 5px;

        @include border-triangle("bottom", #b5b5b5, 0.9em, 0.7em);
    }

    &.open {
        opacity: 1;
    }
}
