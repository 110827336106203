@import '../../../variables';
@import '../../../helpers';

.headerContainer {
    display: flex;
    flex: 1 0 auto;
    flex-shrink: 0;
    align-items: flex-start;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 200px;

    > .label {
        @include date-label();
        font-family: "Roboto-light", sans-serif;
    }

    > .header {
        display: flex;
        flex: 1;
        flex-shrink: 0;
        flex-direction: column;
        padding-left: 10px;
        min-height: 85px;

        > .mainHeading {
            color: $h1-color;
            font-size: 17px;
            margin: 0;

            &.read {
                font-weight: 400;
            }
        }

        > .subHeading {
            margin-top: 5px;
            color: #757575;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
            font-family: "roboto-condensed";
        }
    }
}
