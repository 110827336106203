@import '../../variables';

.mainLabel {
    margin-bottom: $gutter-width;
}

.row {
    display: flex;

    .col {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}

.select {
    width: 100%;
    max-width: 400px;
}

.checkboxInput:nth-child(n) {
    margin: 6px 0;
}
