@import "../../../variables";

$iconSizeMobile: 20px;
$iconSizeDesktop: 25px;
$gutter: 30px;

.stepHeading {
    font-family: roboto;
    color: #555;
    font-weight: bold;
    font-size: 14px;
    display: inline-flex;

    div {
        padding-left: 5px;
        color: #868686;
        font-weight: 500;
        display: flex;
    }
}

.externalLink {
    display: inline-flex;
    font-family: roboto-condensed;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 75%;
    padding: 10px 30px 10px 0px;
}

    
.icon {
    margin-top: -4px;
    margin-left: -8px;
    fill: #959595;
    width: $iconSizeMobile;
    height: $iconSizeMobile;
    transition: transform 0.5s;

    @media (min-width: 600px) {
        width: $iconSizeDesktop;
        height: $iconSizeDesktop;
    }
}

.open {
    transform: rotate(-90deg);
}

.dropdownHeader {
    padding-top: 5px;
    display: inline-flex;
    font-family: roboto-condensed;
    font-weight: 500;
    text-decoration: none;
    font-size: 75%;
    color: #333;
    cursor: pointer;
}

.tableHeading {
    font-family: roboto;
    font-weight: 500;
    font-size: 100%;
    color: #858585;
}

.tableContainer {
    max-width: 400px;
    border: 1px solid lightgrey;
    border-radius: 5px;
}

.header {
    color: #868686;
    font-weight: 500;
    display: flex;
    font-family: roboto;
    font-size: 14px;
}

.button {
    margin: $gutter 0 0;

    @media (min-width: 960px) {
        margin: ($gutter * 1.5) 0 0;
    }
}

.error {
    border: 1px solid $error-color;

    &:focus {
        border-color: $error-color;
        box-shadow: 0 0 4px 0 $error-color;
    }
}

.linebreak {
    margin-bottom: 10px;
}

.QRcontainer {
    max-width: 250px;
    max-height: 250px;
}

.textInput {
    width: 100%;
    max-width: 200px;
}