@import '../../variables';

.row {
    &:nth-child(n + 2) {
        margin-top: ($gutter-width / 2);

        @media (min-width: 600px) {
            margin-top: $gutter-width;
        }
    }
}

.errorText, .helpText {
    display: block;
    margin-top: 2px;
    font-size: 0.97em;
}

.errorText {
    color: #DC3545;
}

.helpText {
    color: $input-placeholder-color;
}
