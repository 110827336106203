@import '../../../variables';

.button {
    background-color: #BD3A1C;
    color: #fff;
    font-size: 14px;

    &:hover {
        box-shadow: $raised-shadow;
    }

    &:focus {
        background-color: #32A1EC;
        box-shadow: 0 0 4px 0 #32A1EC;
        color: #000000;
    }

    &:active {
        box-shadow: none;
    }

    &[disabled] {
        background: #efefef;
        color: #bbb;
        box-shadow: none;
        cursor: not-allowed;
    }
}
