@import '../../../variables';

.fileListEntry {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.nativeInput {
    display: none;
}

.uploadButton {
    padding: 8px 10px;

    .fileListEntry + & {
        margin-top: 30px;
    }
}

.removeFileButton {
    display: block;
    background: none;
    border: none;
    padding: 2px;
    margin-left: 30px;
}

.removeIcon {
    display: block;
    fill: #666;
    width: 20px;
    height: 20px;
}

.fileError {
    margin-left: 30px;
    color: #C83341;
}

.extraFieldWrapper {
    margin-left: 30px;
}

.fieldLabel {
    margin: -1.5em 0px 0px 0px;
}
