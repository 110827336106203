@import "../../../variables";

.container {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    background: #fff;
    transition: opacity 0.6s 1.4s;
    opacity: 1;
}

.fadeOut {
    opacity: 0;
}
