@import '../../../variables';

.container {
    display: flex;
    align-items: center;
    position: relative;
    opacity: 1;
    padding: $gutter-width ($gutter-width / 2);
    border-radius: 6px;
    color: #fff;
    font-size: 0.875em;
    box-shadow: $container-shadow;
    white-space: pre-wrap;

    & + & {
        margin-top: $gutter-width;
    }

    &.warn { background-color: $warn-color; }
    &.success { background-color: $success-color; }
    &.error { background-color: $error-color; }
    &.info { background-color: $info-color; }

    @media (min-width: 600px) {
        font-size: 1em;
    }

    @media (min-width: 960px) {
        padding: 50px $gutter-width;
        font-size: 1.1em;
        opacity: 0.8;
    }
}

.icon {
    margin-right: $gutter-width;

    > svg {
        display: block;
        width: 22px;
        height: 22px;
        fill: currentColor;

        .info & {
            > circle {
                fill: none;
                stroke: currentColor;
                stroke-width: 2;
            }
        }
    }
}

.closeButton {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0px;
    padding: 0px;
    background: none;
    border: none;
    color: inherit;

    > svg {
        display: block;
        width: 24px;
        height: 24px;
        fill: currentColor;
    }
}
