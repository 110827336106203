@import '../../../variables';

.button {
    display: inline-block;
    background: transparent;
    padding: 0px;
    color: #666;
    min-height: 0px;
    min-width: 0px;
    font-size: 14px;

    .underline {
        margin-top: 2px;
        position: relative;
        height: 3px;
        width: 100%;
        background: #e2e2e2;
        border-radius: 1.5px;
    }

    .underlineFill {
        position: relative;
        left: 0px;
        height: 3px;
        width: 0%;
        border-radius: 1.5px;
        transition: width 0.6s ease;
    }

    &:hover {
        .underlineFill {
            width: 100%;
        }
    }

    &[disabled] {
        cursor: not-allowed;
    }
}
