@import '../../../variables';

.wrapper {
    position: relative;
    width: 100px;
}

.input {
    composes: input from './NumberInput.module.scss';
    width: 100px;
}
