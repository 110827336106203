@import '../../../variables';

.menu {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 0 -20px;
    padding: 0;
    list-style: none;

    // We hide the menu like this instead of display: none as it still needs
    // to be in the DOM for the has-wrapped calculation to work.
    &.wrapped {
        height: 0px;
        overflow: hidden;
    }
}

.menuItem {
    padding: 10px 12.5px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    font-size: 14px;

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
    
    &:nth-child(1) {
        margin-left: 7.5px;
    }

    &.disabled {
        filter: brightness(2);
        cursor: default;
      }
}

.selectionUnderline {
    position: absolute;
    height: 4px;
    bottom: 0px;
    margin-left: 7.5px;
    border-radius: 2px;
    background: $accent-color;
    transition: left ease $ui-animation-duration,
                width ease $ui-animation-duration;
}
