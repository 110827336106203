@import '../../../variables';

$toggle-button-size: 27px;
$toggle-button-margin: 4px;

.toggleContainer {
    display: flex;
    align-items: center;
}

.toggleSwitchButton {
    display: block;
    width: 60px;
    margin: 0px;
    padding: $toggle-button-margin;
    background: #fff;
    border: $input-border-active;
    border-radius: ($toggle-button-size + ($toggle-button-margin * 2)) / 2;

    // Remove the awkward looking focus outline
    outline: none;

    &::-moz-focus-inner {
        border: none;
    }

    // Add a simpler border colour change
    &:hover {
        border: $input-border-hover;
    }

    .toggleStateOff & {
        border: $input-border-disabled;

        &:hover {
            border: $input-border-hover;
        }
    }
}

.toggleSwitchIndicator {
    width: $toggle-button-size;
    height: $toggle-button-size;
    border-radius: 50%;

    transition: background ease $ui-animation-duration,
                box-shadow ease $ui-animation-duration,
                margin-left ease $ui-animation-duration;

    .toggleStateOn & {
        margin-left: calc(100% - #{$toggle-button-size});
        background-color: $accent-color;
        box-shadow: $container-shadow;
    }

    .toggleStateOff & {
        margin-left: 0px;
        background: $input-background-color-disabled;
        box-shadow: none
    }
}

.toggleLabel {
    margin-left: 10px;
    font-weight: 500;

    transition: color ease $ui-animation-duration;

    .toggleStateOn & {
        color: $input-filled-color;
    }

    .toggleStateOff & {
        color: $input-placeholder-color;
    }
}
