@import '../../../variables';
@import '../../../helpers';

.button {
    position: fixed;
    z-index: 101;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    transition: all 0.5s ease;

    @include icon-shadow-elevation(1);

    &:hover {
        @include icon-shadow-elevation(2);
    }
    
    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.visible {
    visibility: visible;
    opacity: 1;
}

.hidden {
    visibility: hidden;
    opacity: 0;
}

.icon {
    margin: 2px 0px 0px 0px;
    transform: rotate(90deg);
}
