@import '../../../variables';

.notificationContainer {
    position: fixed;
    top: ($gutter-width / 2);
    left: ($gutter-width / 2);
    right: ($gutter-width / 2);
    z-index: 1000;

    @media (min-width: 960px) {
        left: auto;
        top: $gutter-width;
        right: $gutter-width;
        width: 30vw;
    }

    @media (min-width: 1140px) {
        width: 25vw;
    }
}

.notification {
    animation: slide-in 0.25s linear;
    margin-bottom: $gutter-width;
}

@keyframes slide-in {
    0% {
        transform: translateX(30vw);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
