@import '../../../variables';

.buttonMenuHeader {
    height: 40px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    color: #555555;
    font-size: 15px;
    font-family: "roboto";
    font-weight: 500;
    line-height: 20px;
}

.buttonMenuBody {
    width: 100%;

    > div {
        border-radius: 0px;
        transform: none;
        transition: none;
        box-shadow: none;

        &:hover {
            transform: none;
            transition: none;
            box-shadow: none;
        }
    }
}

.buttonMenuBodyEmpty {
    width: 100%;

    > div {
        border-radius: 0px;
        transform: none;
        transition: none;
        box-shadow: none;

        &:hover {
            transform: none;
            transition: none;
            box-shadow: none;
        }
    }
}

.buttonMenuFooter {
    height: 80px;
    width: 100%;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

