@import "../../../variables";

.checkboxInputContainer {
    display: flex;
    align-items: center;

    &:nth-child(n + 2) {
        margin-top: $gutter-width;
    }

}

.checkboxCustom {
    position: relative;
    display: block;
    width: 15px;
    height: 15px;
    background: #ffffff;
    border-radius: 4px;
    cursor: pointer;

    transition: background ease $ui-animation-duration,
                border-color ease $ui-animation-duration,
                box-shadow ease $ui-animation-duration;

    &.largeBox {
        width: 25px;
        height: 25px;
        border-radius: 4px;

        > svg {
            width: 22px;
            height: 22px;
            margin: 1px;
        }
    }

    > svg {
        display: block;
        width: 12px;
        height: 12px;
        margin: 1px;
        opacity: 0;

        transition: opacity ease $ui-animation-duration;

        > line {
            stroke: #fff;
            stroke-width: 16;
        }
    }

    :checked + & {
        background: $accent-color;

        > svg {
            opacity: 1;
        }
    }

    &:focus {
        outline: none;
    }
}

.checkboxContainer {
    position: relative;
    display:block;
    width: 15px;
    height: 15px;

    &.largeBox {
        margin-bottom: -20px;
        width: 45px;
        height: 45px;
        border-radius: 4px;
    }

    .checkboxNative, .checkboxCustom, .borderContainer, .borderDefault, .borderChecked, .borderHover {
        position: absolute;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        width: 15px;
        height: 15px;
        margin: 0px;
        pointer-events: auto;

        &.largeBox {
            width: 25px;
            height: 25px;
            border-radius: 4px;
        }
    }

    .checkboxNative {
        opacity: 0;
    }
}

.borderDefault, .borderChecked, .borderHover {
    border: solid 2px;
    border-radius: 4px;
    pointer-events: none;
}

.borderDefault {
    border-color: #929292;
}

.borderChecked {
    opacity: 0;

    .checkboxNative:checked + div + div > & {
        opacity: 1
    }
}

.borderHover {
    border-color: #1592E6;
    opacity: 0;

    .checkboxContainer:hover & {
        opacity: 1;
        cursor: pointer;
    }
}

.checkboxLabel {
    margin-left: 10px;
    color: #7E7E7E;
    font-weight: 500;
    display: flex;
    flex: 1;
}
