@import '../../../variables';

$thumb-size: 12px;

// Define the styles for the track and thumb here as mixins
// as browsers have different ways of applying them and we
// don't want to duplicate loads of code.
@mixin range-track () {
    box-sizing: border-box;
    background: #fff;
    height: 6px;
    border-radius: 2px;
    border: $input-border-active;
}

@mixin range-thumb () {
    box-sizing: border-box;
    background: $accent-color;
    border: none;
    width: $thumb-size;
    height: $thumb-size;
    border-radius: 50%;
    transition: box-shadow ease $ui-animation-duration;
    animation-duration: 10ms;
}

.container {
    display: flex;
    align-items: center;
    position: relative;
    width: 400px;
    padding-top: 1em;
}

.innerContainer {
    position: relative;
    width: 100%;
    height: 16px;
    margin: 0px 10px;
}

.min, .max {
    color: #7E7E7E;
}

.value {
    position: absolute;
    top: 0px;
    left: 0%;
    transform: translate(-50%, -100%) translateX($thumb-size / 2);
    font-weight: 500;
    color: $accent-color;

    // Edge and IE have a transition on the thumb that cannot be removed which leaves the value
    // out of sync while it's being moved. Other browsers snap the thumb instead of animating
    // it so we hide the native thumb in Edge and draw a custom one attached to the value label.
    @mixin ie-range-thumb-hack () {
        display: block;
        position: absolute;
        content: '';
        top: 100%;
        left: 50%;
        transform: translate(-50%, 2px);
        pointer-events: none;

        @include range-thumb();
    }

    // This matches Edge
    @supports (display: -ms-grid) {
        &::after {
            @include ie-range-thumb-hack();
        }
    }

    // This is for IE
    @at-root _:-ms-fullscreen, :root &::after {
        @include ie-range-thumb-hack();
    }
}

.input {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    height: 16px;
    margin: 0px;
    padding: 0px;
    border: none;
    background: transparent;

    // We'll add our own focus styles later
    outline: none;
    // Firefox is weird https://stackoverflow.com/a/21568066/3270837
    &::-moz-focus-outer {
        border: none;
    }

    // Chrome/Webkit
    &::-webkit-slider-runnable-track, &::-webkit-slider-thumb {
        // Without this Chrome will ignore everything we try and do to the slider thumb.
        appearance: none;
    }

    &::-webkit-slider-runnable-track {
        @include range-track();
    }

    &::-webkit-slider-thumb {
        @include range-thumb();

        // For some reason the default thumb position is just below the centre of the
        // track so add a transform here to compensate for that. 4px is the height of the
        // tracks content box.
        transform: translateY(-4px);
    }

    // Firefox
    &::-moz-range-track {
        @include range-track();
    }

    // This is the track to the left of the thumb
    // having it there breaks the layout for some reason.
    &::-moz-range-progress {
        display: none;
    }

    &::-moz-range-thumb {
        @include range-thumb();
    }

    // IE/Edge
    &::-ms-thumb {
        // A custom thumb is added for edge to get around the transition issue so the
        // real one needs to be hidden. It has to remain clickable and be the same size
        // as the visible one so we'll give it the same styles but invisible.
        @include range-thumb();
        opacity: 0;

        // New Edge will apply the styles from -webkit-slider-thumb to this element
        // even though it's used in a completly different way inside the range. Wut?
        //
        // Older versions don't do that so we need to include the block again
        // and remove the position adjustment that might be from the webkit styles.
        transform: none;
    }

    &::-ms-track {
        @include range-track();

        // Hides the tick marks along the input track
        color: transparent;
    }

    // This covers up our value element in older IE
    &::-ms-tooltip {
        display: none;
    }

    // Edge uses these to style the track before and after the thumb
    // In the same was as -moz-range-progress this messes with our layout.
    &::-ms-fill-lower, &::-ms-fill-upper {
        display: none;
    }

    // Hides the tick marks at either end of the input
    &::-ms-ticks-before, &::-ms-ticks-after {
        display: none;
    }

    // The promised focus styles
    &:focus {
        &::-webkit-slider-thumb {
            box-shadow: $focus-shadow;
        }

        &::-moz-range-thumb {
            box-shadow: $focus-shadow;
        }

        &::-ms-thumb {
            box-shadow: $focus-shadow;
        }
    }
}
