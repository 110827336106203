$container-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
$raised-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.16);
$tooltip-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.27);
$focus-shadow: 0px 0px 4px 0px #1592E6;

$elevation-shadow-one: #ddd;
$elevation-shadow-two: #595959;
$elevation-shadow-three: #666;

$icon-elevation-shadow: #999;

$elevation-blur-one: 6px;
$elevation-blur-two: 15px;
$elevation-blur-three: 40px;

$card-fold-size: 50px;

$gutter-width: 30px;

$grey-dark: #454F54;
$grey-light: #505A5E;

$background-color: #eee;
$accent-color: #959595;

$calendar-background: #959595;
$calendar-text-colour: #000000;

$warn-color: #FF8B07;
$success-color: #28A745;
$error-color: #DC3545;
$info-color: #17A2B8;
$tab-colour: #32A1EC;

$h1-color: #333333;

$document-card-fold-color: #E2E2E2;

$ui-animation-duration: 300ms;

$card-border-radius: 6px;

// Forms
$input-border-active: 2px solid #929292;
$input-border-disabled: 2px solid #EFEFEF;
$input-border-hover: 2px solid #1592E6;
$input-border-focus:  2px solid #32A1EC;
$input-border-error: 2px solid #DC3545;
$input-background-color-disabled: #f3f3f3;
$input-placeholder-color: #757575;
$input-filled-color: #666;
