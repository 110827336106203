@import '../../../variables';

.content {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto ($gutter-width / 2);
    border-radius: 0px 0px 8px 8px;

    @media (min-width: 960px) {
        margin: 0 auto $gutter-width;
    }

    &.hasBackground {
        background: #fff;
        box-shadow: $container-shadow;
    }

    &.isPadded {
        padding: ($gutter-width/2);

        @media (min-width: 960px) {
            padding: $gutter-width;
        }
    }
}

.noHeader {
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto ($gutter-width / 2);
    border-radius: 8px;

    @media (min-width: 960px) {
        margin: 0 auto $gutter-width;
    }

    &.hasBackground {
        background: #fff;
        box-shadow: $container-shadow;
    }

    &.isPadded {
        padding: ($gutter-width/2);

        @media (min-width: 960px) {
            padding: $gutter-width;
        }
    }
}

.contentWrapper {
    box-sizing: border-box;

    &.hasGutter {
        margin: 0;

        @media (min-width: 960px) {
            margin: 0 ($gutter-width / 2);
        }

        @media (min-width: 1140px) {
            margin: 0 $gutter-width;
        }
    }
}
