@import '../../../variables';

.steplist-step {
    display: flex;
    align-items: center;

    & + & {
        margin-left: 90px;

        &::before, &::after {
            display: block;
            content: '';
            position: absolute;
            height: 1px;
            transform: translateX(-75px);
        }

        &::before {
            background: #ccc;
            width: 60px;
        }

        &::after {
            background: $accent-color;
            transition: width ease $ui-animation-duration;
            width: 0px;
        }
    }

    &.completed {
        & + & {
            &::after {
                width: 60px;
            }
        }
    }
}

.steplist-step-number {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #ccc;
    color: #fff;
    font-weight: 500;
    text-align: center;
    line-height: 18px;
    font-size: 0.9em;
    transition: background ease $ui-animation-duration;
    transition-delay: $ui-animation-duration * 0.75;

    .completed & {
        background: $accent-color;
    }
}

.steplist-step-label {
    color: #c0c0c0;
    font-weight: 500;
    margin-left: 5px;
    transition: color ease $ui-animation-duration;
    transition-delay: $ui-animation-duration * 0.75;

    .completed & {
        color: #757575;
    }
}
