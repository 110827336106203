@import "../../../variables";

.container {
    position: relative;
    padding: $gutter-width;
    border-radius: 0px 0px 6px 6px;
}

.loadingMask {
    position: absolute;
    display: flex;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: white;
    user-select: none;
    pointer-events: none;
    opacity: 0;
    transition: opacity ease 300ms;
    border-radius: 0px 0px 6px 6px;

    &.visible {
        opacity: 1;
        pointer-events: auto;
    }
}

.buttonRow {
    .detailsButton {
        margin-top: 15px;
        font-size: 14px;
        margin-left: 20px;
    }
}
