@import '../../../../variables';
@import '../../../../helpers';

.document {
    @include card-fold();
}

.roundedBottomOnly {
    border-radius: 0 0 $card-border-radius $card-border-radius;
}

.shadow {
    @include shadow-elevation(1, true);

    &:hover {
        @include shadow-elevation(2, true);
    }
}

.footer {
    max-width: 32px;
}
