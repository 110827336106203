@import '../../variables';

.input {
    composes: input from './controls/TextInput.module.scss';
}

.calendar {
    :global(.react-datepicker__day--outside-month) {
        color: rgba($color: #000000, $alpha: 0.5);
    }

    :global(.react-datepicker__day--selected),
    :global(.react-datepicker__day--in-selecting-range),
    :global(.react-datepicker__day--in-range),
    :global(.react-datepicker__month-text--selected),
    :global(.react-datepicker__month-text--in-selecting-range),
    :global(.react-datepicker__month-text--in-range) {
        background-color: $calendar-background;
        color: $calendar-text-colour;
    }

    :global(.react-datepicker__day--selected:hover),
    :global(.react-datepicker__day--in-selecting-range:hover),
    :global(.react-datepicker__day--in-range:hover),
    :global(.react-datepicker__month-text--selected:hover),
    :global(.react-datepicker__month-text--in-selecting-range:hover),
    :global(.react-datepicker__month-text--in-range:hover) {
        background-color: darken(desaturate($calendar-background, 0.19), 4.90);
        color: $calendar-text-colour;
    }

    :global(.react-datepicker__day--keyboard-selected),
    :global(.react-datepicker__month-text--keyboard-selected) {
        background-color: lighten(desaturate($calendar-background, 0.27), 10.20);
        color: $calendar-text-colour;
    }

    :global(.react-datepicker__day--keyboard-selected:hover),
    :global(.react-datepicker__month-text--keyboard-selected:hover) {
        background-color: darken(desaturate($calendar-background, 0.19), 4.90);
        color: $calendar-text-colour;
    }

    :global(.react-datepicker__day--in-selecting-range ),
    :global(.react-datepicker__month-text--in-selecting-range) {
        background-color: rgba($color: $calendar-background, $alpha: 0.5);
        color: $calendar-text-colour;
    }

    :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected) {
        background-color: $calendar-background;
        color: $calendar-text-colour;
    }

    :global(.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover) {
        background-color: $calendar-background;
        color: $calendar-text-colour;
    }
}
