@import "../../../variables";

.buttonWrapper {
    position: absolute;
    top: 50%;
    right: 0px;
    margin-right: 8px;
    transform: translateY(-50%);
}

.increment, .decrement {
    display: block;
    margin: 0px;
    padding: 0px;
    background: none;
    border: none;

    > svg {
        display: block;
        width: 10px;
        height: 10px;
        fill: #9c9c9c;
    }
}
