@import '../../variables';
@import '~sassdash/index';
@import '~sass-svg/index';

.searchInput {
    cursor: pointer;
    margin: 0 0;
    padding: 8px 30px 8px 12px;
    font-size: 1em;
    border-radius: 25px;
    float: right;
    height: 45px;
    width: 45px;
    transition: width 1s ease-in-out,
                box-shadow 1s ease-in-out,
                background-color 1s ease-in-out;

    background-color: transparent;
    border: $input-border-active;
    background-position: calc(100% - 12px) center;
    background-repeat: no-repeat;
    background-size: 16px 16px;

    /* eslint-disable max-len */
    @include svg((viewBox: 0 0 23 23)) {
        @include svg('path', (
            fill: #818181,
            opacity: 1,
            d: "M16.668 14.668h-1.053l-.373-.36a8.691 8.691 0 10-.933.933l.36.373v1.053l5.667 5.667a1.405 1.405 0 001.987-1.987zm-8 0a6 6 0 116-6 5.992 5.992 0 01-6 6z"
        ));
    }
    /* eslint-enable */

    &:hover {
        border: $input-border-hover;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }

    &::placeholder {
        color: #858585;
        opacity: 1;
    }

    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        padding: 0;
    }
}

.searchInput:focus {
    cursor: auto;
    width: 150%;
    border: solid 2px #333;
    background-color: #FFF;

    @media (max-width: 700px) {
        width: 90%;
    }
}

.searchContainer {
    margin-top: -10px;
    position: relative;
    height: 45px;
    width: auto;
    float: right;

    @media (max-width: 700px) {
      width: 100%;
      top: 175px;
      margin-top: 0;
      position: absolute;
      float: none;
      right: 5%;
    }
}
