@import '../../../variables';
@import '../../../scrollbar';
@import '~sassdash/index';
@import '~sass-svg/index';

.filterButton {
    $textColor: #666;
    $placeholderColor: #757575;

    display: block;
    background: #fff;
    border: $input-border-active;
    width: 100%;
    max-width: 400px;
    height: 37.5px;
    padding: 8px 28px 8px 8px;
    font-size: 1.1em;
    color: $input-placeholder-color;
    font-style: italic;
    font-weight: 300;
    text-align: left;
    border-radius: 4px;

    transition: box-shadow ease $ui-animation-duration;

    // Remove the awkward looking focus outline
    outline: none;

    // Firefox uses a psuedo element for the normal outline
    &::-moz-focus-inner, &::-moz-focusring {
        border: none;
        outline: none;
    }

    // It also has an outline around the option element position which is normally hidden.
    // See this answer for why this works https://stackoverflow.com/a/11603104/3270837
    // !important is needed to workaround bug where this would not override the color
    @supports (-moz-appearance: meterbar) {
        text-shadow: 0 0 0 $placeholderColor;
    }

    // Add a simpler focus state
    &:focus {
        box-shadow: 0px 0px 4px 0px #1592E6;
    }

    &:hover {
        border: $input-border-hover;
    }

    // Hide the default select arrow
    appearance: none;
    &::-ms-expand {
        display: none;
    }

    // Add a nice SVG one as a background image
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    background-size: 24px 24px;

    @include svg((viewBox: 0 0 24 24, "xml:space": preserve)) {
        @include svg('path', (
            fill: #9c9c9c,
            opacity: 1,
            d: "M8.71 11.71l2.59 2.59c.39.39 1.02.39 1.41
                0l2.59-2.59c.63-.63.18-1.71-.71-1.71H9.41c-.89 0-1.33 1.08-.7 1.71z"
        ));
    }
}

.filterButtonText {
    color: $input-filled-color;
    font-style: normal;
    font-weight: 400;
}

.filterButtonDisabled {
    border: $input-border-disabled;
    background: $input-background-color-disabled;
    pointer-events: none;
}

.filterSearch {
    width: 370px;
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    background-size: 16px 16px;

    @include svg((viewBox: 0 0 24 24, "xml:space": preserve)) {
        @include svg('path', (
            fill: #999,
            opacity: 0.7,
            d: "M15.5 14h-.79l-.28-.27a6.5 6.5 0 001.48-5.34c-.47-2.78-2.79-5-5.59-5.34a6.505
                6.505 0 00-7.27 7.27c.34 2.8 2.56 5.12 5.34 5.59a6.5 6.5 0 005.34-1.48l.27.28v.79l4.25
                4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01
                5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z",
        ));
    }
}

.buttonMenu {
    border-radius: 4px;
    max-height: 200px;
    width: 400px
}

.dropDownButton, .brandedDropDownButton {
    padding: 6px 0px 6px 0px;
    border: 0px;
    color: #666;
    width: 350px;
    text-align: left;
    background-color: transparent;
}

.dropDownButton {
    &:hover {
        color: #682f91;
    }
}

.brandedDropDownButton {
    &:hover {
        color: $accent-color
    }
}

.dropDownList {
    @include theme-scrollbar;
    list-style-type: none;
    padding-left: 0;
    overflow-y: auto;
    max-height: 100px;
}
