@import '../../../variables';

.textArea {
    box-sizing: border-box;
    display: block;
    background: #fff;
    border: $input-border-active;
    border-radius: 4px;
    width: 100%;
    max-width: 400px;
    padding: 8px;
    color: #666;
    font-size: 1.1em;

    transition: box-shadow ease $ui-animation-duration,
                border-color ease $ui-animation-duration;

    &::placeholder {
        color: $input-placeholder-color;
        font-style: italic;
        font-weight: 300;
        opacity: 1;
    }

    &.inputBlurred:invalid {
        border: $input-border-error;
        box-shadow: 0px 0px 4px 0px #DC3545;
    }

    &:focus, &:hover {
        border: $input-border-hover;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }

    &[readonly] {
        background: transparent;
        border-color: transparent;
        box-shadow: none;
    }
}
