@import '../../../variables';

.action {
    display: block;
    margin: 0px;
    font-weight: 500;
    background: none;
    border: none;
    text-transform: uppercase;
    font-size: 0.9em;

    // 44px x 44px touch target on smaller screens
    padding: 12px 10px;

    @media (min-width: 960px) {
        padding: 5px;
    }
}
