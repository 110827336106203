@import "./variables";

@function opposite-direction($direction) {
    $map: (
        "top": "bottom",
        "right": "left",
        "bottom": "top",
        "left": "right",
    );

    @if not map-has-key($map, $direction) {
        @error "No opposite direction defined for '#{$direction}'";
    } @else {
        @return map-get($map, $direction);
    }
}

@mixin border-triangle($direction, $color, $width, $height) {
    width: 0px;
    height: 0px;

    $main-border: solid $height $color;
    $side-borders: solid ($width / 2) transparent;

    border-#{opposite-direction($direction)}: $main-border;

    @if $direction == top or $direction == bottom {
        border-left: $side-borders;
        border-right: $side-borders;
    } @else if $direction == right or $direction == left {
        border-top: $side-borders;
        border-bottom: $side-borders;
    }
}

@mixin keyframe-animation($steps, $name: unique-id()) {
    $total-time: 0ms;

    @each $step in $steps {
        $total-time: $total-time + map-get($step, delay);
    }

    animation-duration: $total-time;
    animation-name: $name;
    animation-fill-mode: forwards;

    @keyframes #{$name} {
        $offset: 0ms;

        @each $step in $steps {
            $offset: $offset + map-get($step, delay);
            $percentage: ($offset / $total-time) * 100%;

            #{$percentage} {
                @each $property, $value in map-get($step, styles) {
                    #{$property}: #{$value};
                }
            }
        }
    }
}

@mixin card-fold($card-size: $card-fold-size, $bg-color: white) {
    border-right: 50px solid $bg-color;

    &::after, &::before {
        content: "";
        position: absolute;
        transition: 0.3s ease;
    }

    &::before {
        width: 100%;
        height: $card-size + 5px;
        top: -50px;
        background: $bg-color;
        z-index: -1;
        border-radius: $card-border-radius 0 0 0;
    }

    &::after {
        top: -35px;
        right: -35px;
        z-index: -2;
        border-style: solid;
        border-width: 70px 0 0 70px;
        border-color: $document-card-fold-color;
        transform: rotate(-45deg);
    }
}

@mixin card-label($width, $height, $padding: 10px) {
    $radius: 4px;

    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: #{$width}px;
    max-height: #{$height}px;
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
    padding: $padding;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    font-family: "roboto-condensed";
}

@mixin date-label($padding: 10px) {
    @include card-label(100, 75, $padding);

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -45px;

    > span {
        font-size: 14px;
        height: 25px;
        color: #757575;

        &:not(:only-child) {
            opacity: 0.6;

            &:nth-child(2) {
                letter-spacing: -0.8px;
                line-height: 23px;
                font-size: 29px;
                opacity: 1;
            }

            &:nth-child(3) {
                letter-spacing: -0.3px;
                height: 25px;
            }
        }
    }
}

@mixin shadow-elevation($elevation: 1, $card-fold: false) {
    $shadow: -3px 1px $elevation-blur-one $elevation-shadow-one;

    @if ($elevation == 2) {
        transform: scale(1.03);
        $shadow: -3px 1px $elevation-blur-two $elevation-shadow-two;
    } @else if ($elevation == 3) {
        $shadow: -3px 1px $elevation-blur-three $elevation-shadow-three;
    }

    > div {
        // The fold itself - this is hidden behind all content so no custom shadows needed
        &::after {
            box-shadow: $shadow;
        }
    }

    @if ($card-fold) {
        $before-shadow: $shadow;

        @if ($elevation == 1) {
            $shadow: -3px 2px $elevation-blur-one -2px $elevation-shadow-one;
        } @else if ($elevation == 2) {
            $before-shadow: -6px -1px $elevation-blur-two -3px $elevation-shadow-two;
        } @else if ($elevation == 3) {
            $shadow:
                    -20px 20px $elevation-blur-three -20px $elevation-shadow-three,
                    20px 20px $elevation-blur-three -24px $elevation-shadow-three;
            $before-shadow:
                    -12px -10px $elevation-blur-three -14px $elevation-shadow-three,
                    -13px 48px $elevation-blur-three -14px $elevation-shadow-three;
        }

        > div {
            // Pseudo element - custom shadow needed
            &::before {
                box-shadow: $before-shadow;
            }
        }
    }

    box-shadow: $shadow;
}

@mixin icon-shadow-elevation($elevation: 1) {
    $shadow: 0px 0px $elevation-blur-one $icon-elevation-shadow;

    @if ($elevation == 2) {
        $shadow: 0px 4px 6px $icon-elevation-shadow;
    } @else if ($elevation == 3) {
        $shadow: 0px 8px 8px $icon-elevation-shadow;
    }

    box-shadow: $shadow;
}

@mixin line-clamp($numLines: 1, $lineHeight: 25px) {
    overflow: hidden;
    text-overflow: -o-ellipsis-lastline;
    text-overflow: ellipsis;
    display: block;
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: $numLines;
    -webkit-box-orient: vertical;
    line-height: $lineHeight;
    max-height: $numLines * $lineHeight;
}