@import '../../../../helpers';

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    height: 100%;
    box-sizing: border-box;
    border-radius: $card-border-radius;
}

.contextWrapper {
    transition: 0.3s ease;
    background: white;
    border-radius: $card-border-radius;
}

.shadow {
    @include shadow-elevation(1);
}
