@import '../../../variables';

.contentContainer {
    box-sizing: border-box;
    width: 100%;
    margin: 0 0 ($gutter-width / 2);

    @media (min-width: 960px) {
        margin: 0 0 $gutter-width;
    }

    &.boxed {
        max-width: 860px;
        margin: 0 auto;

        @media (min-width: 960px) {
            margin: ($gutter-width / 2) auto;
        }

        @media (min-width: 1140px) {
            margin: $gutter-width auto;
        }
    }
}
