@import '../../../variables';

.wrapper {
    display: flex;
}

.container {
    display: flex;
    align-items: stretch;
    border-radius: 2px;
    cursor: pointer;
    overflow: visible;

    &::after {
        display: table;
        content: '';
    }
}

.nativeInput {
    display: none;
}

.button {
    padding: 8px 10px;
}
