@import '../../../../variables';
@import '../../../../helpers';

.announcement {
    height: 80px;
    width: 100%;
    padding: 10px 10px 10px 46px;

    &:hover {
        transition: background 0.3s ease;
        background-color: #efefef;
    }
}

.announcementAvatar {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 13px);
    left: 10px;

    line-height: 22px;
    text-align: center;
    font-size: 11px;
    font-family: "roboto";
    font-weight: 500;
    text-transform: uppercase;

    @media (min-width: 600px){
        line-height: 26px;
        height: 26px;
        width: 26px;
        font-size: 13px;
    }
}

.announcementHeader {
    margin: auto 0px 0px 0px;

    @include line-clamp(2, 20px);

    color: #111111;
    font-size: 15px;
    font-family: "roboto";
    font-weight: 600;

    &.read {
        font-weight: 400;
    }
}

.announcementType {
    max-height: 20px;
    line-height: 20px;
    margin: 0px 0px auto 0px;

    color: #767676;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    font-family: "roboto-condensed";
}

.announcementAge {
    position: absolute;
    bottom: 10px;
    right: 10px;

    font-size: 14px;
    font-family: "roboto";
    font-weight: 400;
    color: #757575;
}
