@import '../../variables';
@import '~sassdash/index';
@import '~sass-svg/index';

.showPassword {
    display: flex;
    align-items: center;
    height: 40px;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    color: #BBB;

    @media (min-width: 600px) {
        margin-left: 15px;
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}

.passwordRow {
    @media (min-width: 600px) {
        display: flex;
    }
}

.showPasswordIcon {
    transition: fill 0.3s ease;
}

.buttonText {
    text-transform: uppercase;
    display: inline-block;
    margin-left: 7px;
}
