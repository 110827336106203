@import '../../../variables';

.container {
    display: flex;
    align-items: center;

    &:nth-child(n + 2) {
        margin-top: $gutter-width;
    }
}

.native {
    display: none;
}

.custom {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: $input-border-active;
    border-radius: 50%;
    cursor: pointer;

    transition: background ease $ui-animation-duration,
                border-color ease $ui-animation-duration,
                box-shadow ease $ui-animation-duration;

    > div {
        width: 12px;
        height: 12px;
        background: $accent-color;
        border-radius: 50%;
        opacity: 0;

        transition: opacity ease $ui-animation-duration;
    }

    :checked + & {
        border-color: $accent-color;

        > div {
            opacity: 1;
        }
    }

    &:focus {
        outline: none;
        box-shadow: $focus-shadow;
    }

    &:hover {
        border: $input-border-hover;
    }
}

.label {
    margin-left: 10px;
    color: #666;
    font-weight: 500;
}
