@import '../../../helpers';

.datedHeader {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-items: left;
    align-items: unset;

    height: 100%;
    width: 100%;
    margin: 0px;
}

.headerTitle {
    margin: auto auto 0px 0px;
    color: #555555;
    font-size: 16px;
    font-family: "roboto";
    font-weight: 500;
    
    @include line-clamp(2, 30px);

    @media (min-width: 600px) {
        font-size: 22px;

        @include line-clamp(1, 30px);
    }
}

.headerDate {
    max-height: 20px;
    line-height: 25px;
    margin: 0px auto auto 0px;
    color: #757575;
    font-size: 13px;
    font-family: "roboto";
    font-weight: 400;

    @media (min-width: 600px) {
        font-size: 14px;
    }
}