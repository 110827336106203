.avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    line-height: 1em;
}

.icon {
    display: block;
    width: 50%;
    height: 50%;
    fill: #fff;
}
