@import '../../../variables';

.validationIconRow {
    margin-bottom: 1em;
    display: flex;
    align-items: center;

    &:last-of-type {
        margin-bottom: 0;
    }
}

.validationIcon {
    width: 22px;
    height: 22px;
    margin-right: ($gutter-width * 0.75);
}

.passed {
    fill: $success-color;
}

.failed {
    fill: $error-color;
}
