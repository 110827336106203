@import '../../variables';

.inputLabel {
    display: flex;
    align-items: center;
    color: $input-placeholder-color;
    font-weight: 500;
    margin-bottom: 5px;
}

.optional {
    font-size: 0.875em;
    color: #767676;
    margin: 0 0 0 10px;
}
