@import '../../../../variables';

.contentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (min-width: 800px) {
        padding: 0px $gutter-width;
        box-sizing: border-box;
    }
}

.securityCard {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    max-width: 960px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    min-height: calc(33vh);
}

.cardHeader {
    background: $info-color;
    border-radius: 8px 8px 0 0;
    font-size: 1em;
    display: flex;
    align-items: center;

    @media (min-width: 600px) {
        height: 130px;
        font-size: 1.143em;
    }
}

.cardHeaderInner {
    color: #FFF;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 19px;
    margin: 0;
    padding: ($gutter-width / 2) $gutter-width;
    font-weight: 600;
}

.icon {
    display: block;
    fill: #FFF;
    width: 22px;
    min-width: 22px;
    height: 22px;
    min-height: 22px;
    margin: 0 25px 0 0;
}

.cardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 100%;
    padding: ($gutter-width / 2);

    @media (min-width: 600px) {
        padding: $gutter-width;
    }
}
