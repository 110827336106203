@import "../../../variables";
@import "../../../helpers";

$height: 80px;
$avatar-size: 40px;
$avatar-border: 7px;

.container {
    position: relative;
    background-color: #505A5E;
    height: $height;
    border-radius: 6px 6px 0px 0px;

    &.error {
        @include keyframe-animation((
            ( delay: 0s, styles: ( background-color: $grey-light ) ),
            ( delay: 500ms, styles: ( background-color: $error-color ) ),
            ( delay: 800ms, styles: ( background-color: $error-color ) ),
            ( delay: 2000ms, styles: ( background-color: $grey-light ) ),
        ));
    }

    &.success {
        @include keyframe-animation((
            ( delay: 0s, styles: ( background-color: $grey-light ) ),
            ( delay: 500ms, styles: ( background-color: $success-color ) ),
            ( delay: 800ms, styles: ( background-color: $success-color ) ),
            ( delay: 2000ms, styles: ( background-color: $grey-light ) ),
        ));
    }
}

.avatarCircle {
    box-sizing: content-box;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.avatarCutout {
    composes: avatarCircle;
    width: $avatar-size;
    height: $avatar-size;
    background: #fff;
    border: solid $avatar-border;
}

.avatarContainer {
    composes: avatarCircle;
    width: $avatar-size;
    height: $avatar-size;
}

.avatarWrapper {
    transition: transform ease 600ms;
    transition-delay: 1000ms;
    transform: translate(0px, 0px);
}

.messageContainer {
    $message-height: $height - ($avatar-size / 2);

    position: absolute;
    bottom: 0px;
    width: 100%;
    height: $message-height;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.messageContainerInner {
    padding: 0px 10px;
    text-align: center;
}

.avatar {
    transition: background-color ease 500ms, width ease 1000ms, height ease 1000ms;
    transition-delay: 0ms, 1000ms, 1000ms;
    opacity: 1;
}
