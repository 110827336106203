@import '../../../variables';

.button {
    display: inline-block;
    background: transparent;
    color: #666;
    font-size: 14px;

    &:hover {
        background-color: #ddd;
    }

    &[disabled] {
        color: #bbb;
        cursor: not-allowed;
    }
}