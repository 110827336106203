@import '../../../variables';

.menu {
    position: absolute;
    top: 0;
    left: 0;
    padding: ($gutter-width / 2);
    background: #fff;
    box-shadow: $container-shadow;
    transition: opacity ease $ui-animation-duration;
    z-index: 100;
}

.menuWithCloseButton {
    padding: ($gutter-width * 1.5) ($gutter-width / 2) ($gutter-width / 2);
}

.hiddenMenu {
    composes: menu;
    opacity: 0;
}

.visibleMenu {
    composes: menu;
    opacity: 1;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    width: 20px;
    height: 20px;

    > .closeButtonLine {
        width: 21px;
        height: 3px;
        background: #858585;
        border-radius: 8px;
        margin-left: auto;
        margin-right: auto;

        &:nth-child(1) {
            transform: translateY(1.5px) rotate(45deg);
        }

        &:nth-child(2) {
            transform: translateY(-1.5px) rotate(-45deg);
        }
    }

    :global(.__flag-user-is-not-tabbing) &:focus {
        outline: none;
    }
}
