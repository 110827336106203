@import '../../../variables';
@import '../../../helpers';

.documentBody {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    flex-shrink: 0;

    > .documentNotice {
        @include card-label(40, 14, 6px 4px 6px 4px);

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            line-height: 10px;
            position: relative;
        }
    }
}
